import * as React from 'react';
import { 
    extractPictureGalleryItemInwinkImage,
    extractPictureGalleryItemMetadata, type IPictureItem } from './bloc.picturegallery.base';
import { DynLabel } from '../../../services/i18nservice';
import { InwinkImage } from '@@components/images';
import { IAssetEntity } from '@@data/assets';

export interface IPictureGalleryItemProps {
    item: string | IPictureItem | IAssetEntity;
    itemIndex: number;
    className?: string;
    defaultSize?: number;
    availableSizes?: number[];
    isLarge?: boolean;
    progressive?: boolean;
    style? : any;
    onItemClicked?: (itemIdx: number) => void;
}

export class PictureGalleryItem extends React.PureComponent<IPictureGalleryItemProps, any> {
    itemClicked: (arg) => void;

    constructor(props: IPictureGalleryItemProps) {
        super(props);
        if (props.onItemClicked) {
            this.itemClicked = (arg) => {
                arg.preventDefault();
                arg.stopPropagation();
                this.props.onItemClicked(this.props.itemIndex);
            };
        }
    }

    render() {
        const item = this.props.item;
        //let pic = "";
        let onClick;
        let content; 
        let title;

        if (this.props.onItemClicked) {
            onClick = this.itemClicked;
        }
        const defaultSize = this.props.defaultSize || 600;
        const availableSizes = this.props.availableSizes || [400, 600];
        const pic = extractPictureGalleryItemInwinkImage(item, true);
        const image = <InwinkImage
            className="galleryitem-image"
            image={pic}
            defaultSize={defaultSize}
            lazy
            availableSizes={availableSizes}
            progressive={this.props.progressive}
        />;
        const metadata = extractPictureGalleryItemMetadata(item);

        if (metadata.hasVideo) {
            content = <div className="player"><i className="inwink-player-play" /></div>;
        }

        if (metadata.title) {
            title = <div className="title-layer"><DynLabel i18n={metadata.title} /></div>;
        }
        //const itemStyle = assign({}, this.props.style);
        // pic = replaceAll(pic, "'", "\'");
        //itemStyle.backgroundImage = "url(\"" + pic + "\")";

        return <div
            style={this.props.style}
            onClick={onClick}
            className={"picturegalleryitem " + (this.props.className || "") + (onClick ? " clickable" : "")}
        >
            {image}
            {content}
            {title}
        </div>;
    }
}
