import * as React from 'react';
//import { AspectRatio } from '@inwink/responsive/aspectratio';
import { IBlocPictureGalleryProps } from './bloc.picturegallery.base';
import { PictureGalleryItem } from './bloc.picturegallery.item';

import './bloc.picturegallery.mozzaik.less';

//const ratio = 480 / 260;
export class MozzaikPictureGallery extends React.PureComponent<IBlocPictureGalleryProps, any> {
    renderItem(idx: number) {
        const isLarge = idx === 0;
        return (
            <PictureGalleryItem
                key={"item" + idx}
                item={this.props.items[idx]}
                itemIndex={idx}
                isLarge={isLarge}
                defaultSize={isLarge ? 800 : 600}
                availableSizes={isLarge ? [400, 600, 800 ] : [400, 600]}
                onItemClicked={this.props.onItemClicked}
                className={(isLarge ? "large" : "")}
                progressive
            />
        );
    }

    // renderPictures(width, height) {
    //     const itemColumns = this.props.itemColumns || 3;
    //     const itemMargin = this.props.itemMargin || 4;
    //     const itemW = Math.floor(Math.floor(width - (itemColumns * itemMargin)) / itemColumns);
    //     const itemH = Math.floor(itemW / ratio);
        
    //     let defaultItemStyle = '';
    //     const singleItemStyle = `
    //         .galeryitems-${this.props.id} .picturegalleryitem {
    //             width: ${itemW}px;
    //             height : ${itemH}px;
    //             margin-right:${itemMargin}px;
    //             margin-bottom:${itemMargin}px;
    //         }
    //     `;
    //     let largeItemStyle = `
    //         .galeryitems-${this.props.id} .picturegalleryitem.large {
    //             width: ${2 * itemW + itemMargin}px;
    //             height : ${2 * itemH + itemMargin - 1}px
    //         }
    //     `;
    //     if (this.props.display === "default") {
    //         largeItemStyle = `
    //             .galeryitems-${this.props.id} .picturegalleryitem {
    //                 width: ${2 * itemW + itemMargin}px;
    //                 height : ${2 * itemH + itemMargin - 1}px;
    //             }
    //         `;
    //         defaultItemStyle = `
    //             .galeryitems-${this.props.id} .picturegalleryitem {
    //                 margin: 0 auto ${itemMargin}px auto;
    //             }
    //         `;
    //     }
    //     const itemstyle = `
    //     ${singleItemStyle}
    //     ${largeItemStyle}
    //     ${defaultItemStyle}`;

    //     const items = this.props.items.map((item, idx) => this.renderItem(idx));

    //     return <div className={"galeryitems-" + this.props.id} style={{ marginRight: -itemMargin }}>
    //         <style dangerouslySetInnerHTML={{ __html: itemstyle }}></style>
    //         {items}
    //     </div>;
    // }

    render() {
        const items = this.props.items.map((item, idx) => this.renderItem(idx));
        return <div className={"galeryitems galeryitems-" + this.props.id}>
            {items}
        </div>;
        // return <AspectRatio ratio={this.props.aspectRatio || ratio} deferredBy={30}>
        //     {(width, height) => this.renderPictures(width, height)}
        // </AspectRatio>;
    }
}