import * as React from 'react';
import { guid } from '@inwink/utils/methods/guid';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { PictureGalleryFull } from './bloc.picturegallery.fullview';
import { MozzaikPictureGallery } from './bloc.picturegallery.mozzaik';
import { CarouselPictureGallery } from './bloc.picturegallery.carousel';
import { IVideoConfig } from '../../../components/video';
import { States } from '../../../services/services';
import { IAssetEntity } from '@@data/assets';

import './bloc.picturegallery.less';

export interface IPictureItem {
    thumbnail: string;
    thumbnailSmall?: string;
    thumbnailLarge?: string;
    picture: string;
    video: IVideoConfig;
    title: VisualTheme.IAppLabel;
    description: VisualTheme.IAppLabel;
}

export interface IBlocPictureGalleryProps {
    id: string;
    contentClassName: string;
    disableFullScreenView: boolean;
    display: "carousel" | "thumbcarousel" | "carouselwiththumb" | "mozzaik" | "default";
    sliderSettings: any;
    i18n: States.i18nState;
    themeId: string;
    aspectRatio?: number;
    maxItems?: number;
    itemMargin?: number;
    itemColumns?: number;
    itemWidth?: number;
    itemHeight?: number;
    showPager?: boolean;
    children?: any;
    items: (string | IPictureItem | IAssetEntity)[];
    onItemClicked?: (itemIdx: number) => void;
}

export class PictureGalleryBase extends React.PureComponent<IBlocPictureGalleryProps, any> {
    id: string;

    component: any;

    constructor(props: IBlocPictureGalleryProps) {
        super(props);
        this.id = guid();
        this.component = MozzaikPictureGallery;

        if (this.props.display === "carousel") {
            this.component = (carouselprops: IBlocPictureGalleryProps) => <CarouselPictureGallery
                {...carouselprops}
                applyRationOnItem={false}
            />;
        }
        if (this.props.display === "thumbcarousel") {
            this.component = (carouselprops: IBlocPictureGalleryProps) => <CarouselPictureGallery
                {...carouselprops}
                applyRationOnItem={true}
            />;
        }
        if (this.props.display === "carouselwiththumb") {
            this.component = (carouselprops: IBlocPictureGalleryProps) => <CarouselPictureGallery
                {...carouselprops}
                applyRationOnItem={false}
                bottomThumbCarousel={true}
            />;
        }

        this.state = {};
    }

    hideFullView = () => {
        this.setState({ showFullView: undefined });
    };

    itemClicked = (itemIdx: number) => {
        this.setState({ showFullView: itemIdx });
    };

    render() {
        const props = this.props;
        if (!props || !props.items.length) {
            return <div className="empty" />;
        }

        let fullview;

        if (this.state.showFullView !== undefined) {
            fullview = <PictureGalleryFull {...props} onHide={this.hideFullView} selected={this.state.showFullView} />;
        }

        return <>
            <div className={this.props.contentClassName + " picturegallerybase display-" + this.props.display}>
                {React.createElement(this.component, {
                    ...props,
                    onItemClicked: this.props.disableFullScreenView ? null : this.itemClicked
                })}
            </div>
            {fullview}
        </>;
    }
}

export function getEntityItems(entityFields: string[], entity: any) {
    if (entityFields && entity) {
        const items: IPictureItem[] = [];
        entityFields.forEach((efield) => {
            const picture = entity[efield];
            if (picture) {
                items.push({
                    thumbnail: picture.thumbUrl || picture.url,
                    thumbnailLarge: picture.thumbLargeUrl || picture.thumbUrl || picture.url,
                    picture: picture.url,
                    title: null,
                    description: null,
                    video: null
                });
            }
        });

        if (items.length) {
            return items;
        }
    }

    return null;
}

export function getNestedEntityItems(fieldName, entity: any) {
    const nestedEntities = fieldName && entity && entity[fieldName];
    if (nestedEntities) {
        const items: IPictureItem[] = [];
        nestedEntities.filter((f) => f).sort((a, b) => {
            if (a.index > -1 && b.index > -1) {
                return a.index - b.index;
            }
            return 1;
        }).forEach((efield) => {
            const picture = efield.picture;
            const poster = picture && picture.thumbUrl;
            let video;

            const cfgIframe: IVideoConfig = {
                customPlayerUrl: efield.videoUrl,
                poster
            };

            if (efield.videoSource === undefined) {
                video = efield.video;
                if (video) {
                    video.poster = poster;
                }

                if (efield.videoUrl) {
                    video = cfgIframe;
                }
            } else if (efield.videoSource === 'videoIframe') {
                if (efield.videoUrl)
                    video = cfgIframe;
            } else if (efield.videoSource === 'videoFile') {
                if (efield.video) {
                    video = efield.video;
                    video.poster = poster;
                }
            }

            items.push({
                thumbnail: picture ? (picture.thumbUrl || picture.url) : null,
                thumbnailLarge: picture ? (picture.thumbLargeUrl || picture.thumbUrl || picture.url) : null,
                picture: picture && picture.url,
                title: efield.title,
                description: efield.description,
                video
            });
        });

        if (items.length) {
            return items;
        }
    }

    return null;
}

export function extractPictureGalleryItemInwinkImage(item: string | IPictureItem | IAssetEntity,
    invertPictureImage = false): string | IAssetEntity {
    if (typeof item === "string") {
        return item;
    } else {
        let imageSrc = null;
        if (Object.prototype.hasOwnProperty.call(item, "assetType")) {
            const asset = item as IAssetEntity;
            if (asset.assetType == "Image" && asset.file
                || ((asset.assetType == "Document" || asset.assetType == "Video") && asset.thumbnail)
            ) {
                imageSrc = asset;
            }
        } else {
            const pictureItem = item as IPictureItem;
            imageSrc = invertPictureImage
                ? pictureItem.picture || pictureItem.thumbnail
                : pictureItem.thumbnail || pictureItem.picture;
        }
        return imageSrc;
    }
}

export function extractPictureGalleryItemCMSVideo(item: string | IPictureItem | IAssetEntity): IVideoConfig {
    if (typeof item === "string") {
        return null;
    } else {
        if (Object.prototype.hasOwnProperty.call(item, "assetType")) {
            const asset = item as IAssetEntity;
            if (asset.assetType == "Video") {
                return { url: typeof asset.file == "object" ? asset.file.url : asset.file };
            }
        } else {
            const pictureItem = item as IPictureItem;
            return Object.assign({}, pictureItem.video, {
                poster: pictureItem.thumbnailLarge
            });
        }
    }
}

export function extractPictureGalleryItemMetadata(item: string | IPictureItem | IAssetEntity)
    : { hasVideo: boolean, title: VisualTheme.IAppLabel, description: VisualTheme.IAppLabel } {

    if (typeof item === "string") {
        return { hasVideo: false, title: null, description: null };
    } else {
        if (Object.prototype.hasOwnProperty.call(item, "assetType")) {
            const asset = item as IAssetEntity;
            return { hasVideo: asset.assetType == "Video", title: asset.title, description: null };
        } else {
            const pictureItem = item as IPictureItem;
            return { hasVideo: pictureItem.video ? true : false, title: pictureItem.title, description: pictureItem.description };
        }
    }
}