import * as React from 'react';
import { AspectRatio } from '@inwink/responsive/aspectratio';
import Slider from 'react-slick';
import { extractPictureGalleryItemInwinkImage,
    extractPictureGalleryItemMetadata, type IBlocPictureGalleryProps } from './bloc.picturegallery.base';
import { PictureGalleryItem } from './bloc.picturegallery.item';
import { InwinkImage } from '@@components/images';
import './bloc.picturegallery.carousel.less';

const SliderCtrl: any = Slider;

export interface ICarouselPictureGalleryProps extends IBlocPictureGalleryProps {
    applyRationOnItem: boolean;
    bottomThumbCarousel?: boolean;
}

function PrevArrow(props: any) {
    return <div onClick={props.onClick} className={props.className} style={props.style} data-role={props["data-role"]}>
        <button className="inwink-chevronsmall-left" type="button" />
    </div>;
}

function NextArrow(props: any) {
    return <div onClick={props.onClick} className={props.className} style={props.style} data-role={props["data-role"]}>
        <button className="inwink-chevronsmall-right" type="button" />
    </div>;
}

export class CarouselPictureGallery extends React.PureComponent<ICarouselPictureGalleryProps, any> {
    prevArrowComponent: any;

    nextArrowComponent: any;

    slider: React.ClassicComponent<Slider> = null;

    slider2: React.ClassicComponent<Slider> = null;

    constructor(props: ICarouselPictureGalleryProps) {
        super(props);
        this.prevArrowComponent = React.createElement(PrevArrow);
        this.nextArrowComponent = React.createElement(NextArrow);
        this.state = {};
    }

    renderItem(idx: number, large: boolean, allowClick: boolean, itemStyle?: any) {
        return <div key={"item" + idx}>
            <PictureGalleryItem
                style={itemStyle}
                item={this.props.items[idx]}
                isLarge={large}
                itemIndex={idx}
                onItemClicked={allowClick ? this.props.onItemClicked : null}
            />
        </div>;
    }

    renderFixedCarousel(ratio, width, height) {
        let style; let
            itemStyle;
        const itemWidth = this.props.itemWidth || 270;
        const itemMargin = this.props.itemMargin || 2;

        let nbitems = Math.floor(width / (itemWidth + (2 * itemMargin)));
        if (nbitems > this.props.items.length) {
            nbitems = this.props.items.length;
        }
        if (nbitems <= 0) {
            nbitems = 1;
        }

        if (width && height) {
            if (nbitems === 1) {
                style = { width: width + "px", height: height + "px" };
            }

            const itemWidth2 = (width / nbitems) - (2 * itemMargin);
            itemStyle = {
                width: "calc(100% - " + Math.floor(2 * itemMargin) + "px)",
                height: Math.floor(itemWidth2 / ratio),
                margin: itemMargin
            };
        }

        const items = this.props.items.map((item, idx) => {
            return this.renderItem(idx, false, true, itemStyle);
        });

        return <div className="picturegallerycarousel" style={style}>
            <Slider
                {...this.props.sliderSettings}
                prevArrow={this.prevArrowComponent}
                slidesToShow={nbitems}
                slidesToScroll={nbitems}
                nextArrow={this.nextArrowComponent}
                dots={nbitems < this.props.items.length && this.props.showPager}
            >{items}</Slider>
        </div>;
    }

    renderCarousel(ratio, width, height) {
        let style;
        const items = this.props.items.map((item, idx) => {
            return this.renderItem(idx, true, true);
        });

        if (width && height) {
            style = { width: width + "px", height: height + "px" };
        }

        return <div className="picturegallerycarousel fixedsize" style={style}>
            <Slider
                {...this.props.sliderSettings}
                prevArrow={this.prevArrowComponent}
                nextArrow={this.nextArrowComponent}
                dots={this.props.showPager}
            >{items}</Slider>
        </div>;
    }

    componentDidMount() {
        this.checkNav();
    }

    checkNav = () => {
        if (this.state.nav1 !== this.slider || this.state.nav2 !== this.slider2) {
            this.setState({
                nav1: this.slider,
                nav2: this.slider2
            });
        }
    };

    renderDualCarousel(ratio, width, height) {
        const items = this.props.items.map((item, idx) => {
            return this.renderItem(idx, true, true);
        });
        const itemsThumb = this.props.items.map((item, idx) => {
            const pic = extractPictureGalleryItemInwinkImage(item);
            const metadata = extractPictureGalleryItemMetadata(item);
            const content = metadata.hasVideo ? <div className="player"><i className="inwink-player-play" /></div> : null;
            let image = null;
            if (pic) {
                image = <InwinkImage className="thumbnailimage" image={pic} defaultSize={180} availableSizes={[180, 300]} />;
            }

            const style = typeof pic == "object" ? {} : { backgroundImage: "url(\"" + pic + "\")" };
            return <div key={"item" + idx} className="itemthumbnailwrapper">
                <div className="itemthumbnailcontainer clickable">
                    <div className="itemthumbnail bloc-lightborder" style={style}>
                        {image}
                        {content}
                    </div>
                </div>
            </div>;
        });

        return <div className="picturegallerycarousel dualcarousel">
            <div className="maincarousel-wrapper" style={(width && height) ? { width: width + "px", height: height + "px" } : {}}>
                <Slider
                    {...this.props.sliderSettings}
                    className="maincarousel"
                    asNavFor={this.state && this.state.nav2}
                    ref={(mainslider) => {
                        this.slider = mainslider;
                        if (!this.state.nav1) {
                            setTimeout(() => this.checkNav(), 50);
                        }
                    }}
                    prevArrow={this.prevArrowComponent}
                    nextArrow={this.nextArrowComponent}
                    dots={this.props.showPager}
                >{items}</Slider>
            </div>

            <SliderCtrl
                ref={(subslider) => { this.slider2 = subslider; }}
                className="thumbnailcarousel"
                prevArrow={this.prevArrowComponent}
                nextArrow={this.nextArrowComponent}
                asNavFor={this.state && this.state.nav1}
                slidesToShow={Math.floor(width / 90)}
                swipeToSlide={true}
                focusOnSelect={true}
                dots={false}
            >{itemsThumb}</SliderCtrl>
        </div>;
    }

    render() {
        const ratio = this.props.aspectRatio || 1.777;

        if (this.props.bottomThumbCarousel) {
            return <AspectRatio ratio={ratio}>
                {(width, height) => this.renderDualCarousel(ratio, width, height)}
            </AspectRatio>;
        }
        if (this.props.applyRationOnItem) {
            return <AspectRatio ratio={ratio}>
                {(width, height) => this.renderFixedCarousel(ratio, width, height)}
            </AspectRatio>;
        }

        return <AspectRatio ratio={ratio}>
            {(width, height) => this.renderCarousel(ratio, width, height)}
        </AspectRatio>;
    }
}
