/* eslint-disable max-classes-per-file */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group';
import { SlowFadeTransition } from '../../../components/transitions';
import {
    extractPictureGalleryItemCMSVideo,
    extractPictureGalleryItemInwinkImage,
    extractPictureGalleryItemMetadata, type IBlocPictureGalleryProps,
    type IPictureItem
} from './bloc.picturegallery.base';
import { CMSVideo } from '../../../components/video';
import { DynLabel } from '../../../services/i18nservice';
import { InwinkImage } from '@@components/images';
import { IAssetEntity } from '@@data/assets';

import './bloc.picturegallery.fullview.less';

export interface IPictureGalleryFullProps extends IBlocPictureGalleryProps {
    onHide: () => void;
    selected: number;
}

export class PictureGalleryFull extends React.PureComponent<IPictureGalleryFullProps, any> {
    fullviewNode: HTMLElement;

    constructor(props: IPictureGalleryFullProps) {
        super(props);
        this.fullviewNode = document.createElement("DIV");
        document.body.appendChild(this.fullviewNode);
    }

    componentWillUnmount() {
        if (this.fullviewNode) {
            document.body.removeChild(this.fullviewNode);
            this.fullviewNode = null;
        }
    }

    render() {
        return ReactDOM.createPortal(
            <PictureGalleryFullContent {...this.props} />,
            this.fullviewNode,
        );
    }
}

interface IPictureGalleryFullContentState {
    active: boolean;
    dirty: boolean;
    hide?: boolean;
    current: number;
    initial: number;
}

export class PictureGalleryFullContent extends React.PureComponent<IPictureGalleryFullProps, IPictureGalleryFullContentState> {
    constructor(props: IPictureGalleryFullProps) {
        super(props);
        this.state = {
            active: false,
            current: props.selected,
            dirty: false,
            initial: props.selected
        };
    }

    hide = () => {
        this.setState({
            hide: true
        }, () => {
            setTimeout(() => {
                this.props.onHide();
            }, 200);
        });
    };

    movePrevious = () => {
        this.setState((state) => {
            let current = state.current - 1;
            if (current < 0) {
                current = this.props.items.length - 1;
            }
            return Object.assign({}, state, {
                current: current,
                dirty: true
            } as Partial<IPictureGalleryFullContentState>);
        });
    };

    moveNext = () => {
        this.setState((state) => {
            let current = state.current + 1;
            if (current > this.props.items.length - 1) {
                current = 0;
            }
            return Object.assign({}, state, {
                current: current,
                dirty: true
            });
        });
    };

    escpressed = (arg: KeyboardEvent) => {
        arg.preventDefault();
        arg.stopPropagation();
        if (arg.keyCode === 27) {
            this.hide();
        }
    };

    componentDidMount() {
        document.addEventListener("keyup", this.escpressed);

        setTimeout(() => {
            this.setState({ active: true });
        }, 50);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.escpressed);
    }

    render() {
        const currentitem = this.props.items[this.state.current];
        const hasNext = this.state.current < (this.props.items.length - 1);
        const hasPrevious = this.state.current > 0;

        const content: any = [
            <SlowFadeTransition key={"item" + this.state.current}>
                <PictureGalleryFullItem {...this.props} item={currentitem}
                    videoAutoplay={this.state.initial == this.state.current && !this.state.dirty} />
            </SlowFadeTransition>
        ];

        const themeclass = this.props.themeId;

        return <div className={"blocpicturegaleryfull "
            + themeclass + (this.state.active ? " active" : "")
            + (this.state.hide ? " hide" : "")}
        >
            <div className="blocpicturegaleryfull-wrapper bloctheme">
                <div className="blocpicturegaleryfull-overlay" />
                <div className="blocpicturegaleryfull-content">
                    <div className="nav-previous">
                        {hasPrevious ? <button onClick={this.movePrevious} className="btnnav btnprevious" type="button">
                            <i className="inwink-chevronsmall-left" />
                        </button> : null}
                    </div>
                    <div className="picturecontent">
                        <TransitionGroup component="div" className="picturecontentitems">
                            {content}
                        </TransitionGroup>
                    </div>
                    <div className="nav-next">
                        {hasNext ? <button onClick={this.moveNext} className="btnnav btnprevious" type="button">
                            <i className="inwink-chevronsmall-right" />
                        </button> : null}
                    </div>
                </div>
                <div className="blocpicturegaleryfull-close">
                    <button onClick={this.hide} type="button">
                        <i className="inwink-cancel" />
                    </button>
                </div>
            </div>
        </div>;
    }
}

interface IPictureGalleryFullItemProps extends IPictureGalleryFullProps {
    item: string | IPictureItem | IAssetEntity;
    videoAutoplay?: boolean;
}

export class PictureGalleryFullItem extends React.PureComponent<IPictureGalleryFullItemProps, any> {
    render() {
        const pic = extractPictureGalleryItemInwinkImage(this.props.item, true);
        const metadata = extractPictureGalleryItemMetadata(this.props.item);
        let description;
        let content;
        const style: any = {};
        const isVideo = metadata.hasVideo;
        let noDescription = true;
        if (metadata.title || metadata.description) {
            if (metadata.description && metadata.description[this.props.i18n.currentLanguageCode]) {
                noDescription = false;
            }
            description = <div className="description-panel">
                <DynLabel component="h2" className="title" i18n={metadata.title} />
                <DynLabel component="div" className="description" i18n={metadata.description} />
            </div>;
        }
        if (isVideo) {
            const video = extractPictureGalleryItemCMSVideo(this.props.item);
            if (this.props.videoAutoplay) {
                video.autoplay = true;
            }
            content = <CMSVideo
                className="videowrapper"
                aspectRatio={video.aspectRatio || (16 / 9)}
                video={video}
                displayLanguage={this.props.i18n.currentLanguageCode}
            />;
        } else if (pic) {
            content = <InwinkImage
                className="picture"
                image={pic}
                defaultSize={1920}
                availableSizes={[600, 1024, 1280, 1920]}
            />;
        }

        return <div className={"pictureitem" + (isVideo ? " video" : "") + (noDescription ? " noDescription" : "")}>
            {description}
            <div className="pictureitemcontent" style={style}>
                {content}
            </div>
        </div>;
    }
}
